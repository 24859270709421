<template>
  <div class="container">
    <div class="doctor-prescription p-sm-5">
      <h3 class="title mb-4">UNGGAH HASIL TES LABORATORIUM</h3>
      <!-- Added form for detailed information -->
      <a-form :model="profileData" class="p-1 p-sm-5">
        <div class="row form-item ">
          <div class="col-12 col-sm-2 text-left text-sm-right mr-4">
            <label>Unggah Hasil Tes :</label>
          </div>
          <div class="col-12 col-sm-8">
            <p class="text-left m-0 mb-3">
              Pastikan anda mengunggah foto resep secara lengkap dan jelas
            </p>
            <a-upload
              :max-count="1"
              class="upload-section"
              list-type="picture-card"
              :file-list="fileList"
              @change="handleChange"
              @preview="handlePreview"
              :before-upload="() => false"
            >
              <div v-if="!fileList.length">
                <a-icon type="camera" />
                <div>Unggah Foto</div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" centered>
              <img
                alt="example"
                style="width: 100%;margin-top: 30px; object-fit: cover;"
                :src="previewImage"
              />
            </a-modal>
          </div>
        </div>
        <a-button type="primary" @click="handleSubmit">KIRIM</a-button>
      </a-form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { Upload, Form, Input, Button } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  uploadLabImage,
  uploadLabResult,
  getPatientProgram,
  createLabTest,
  updateTestLab,
} from '../../../../services/axios/pulih'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  components: {
    'a-upload': Upload,
    'a-form': Form,
    // 'a-input': Input,
    'a-button': Button,
  },
  setup() {
    const fileList = ref([])
    const profileData = ref({
      namaDokter: 'John Doe',
      jenisTerapi: 'LYNPARZA',
      email: 'johndoe@example.com',
    })
    const previewVisible = ref(false)
    const previewImage = ref('')
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const store = useStore()
    const handleChange = ({ fileList: newFileList }) => {
      fileList.value = newFileList.slice(-1) // Batasi hanya 1 gambar yang diunggah
    }
    const route = useRoute()
    const router = useRouter()
    const dataProgramPatient = ref()
    const testLabTypeId = computed(() => store.state.testLabTypeId)
    onMounted(async () => {
      if (!testLabTypeId.value) {
        router.push('/mainProgram')
      }
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)
      dataProgramPatient.value = res
      // console.log(res, 'inidatapasien')
      // console.log(res.testLabs, 'iniLab')
    })
    const handleSubmit = async () => {
      if (fileList.value.length === 0) {
        console.error('No file selected for upload.')
        return
      }

      const file = fileList.value[0].originFileObj // Ambil file yang akan diupload
      try {
        const res = await uploadLabImage('Lab Test Result', file)
        console.log('Upload response:', res)
        let testLabId = ''
        if (!dataProgramPatient.value.programs[0].testLab) {
          const { data } = await createLabTest(testLabTypeId.value, dataProgramPatient.value.id)
          console.log(data.id, 'initestlabselftest')
          testLabId = data.id
        } else {
          testLabId = dataProgramPatient.value.programs[0].testLab.id
        }
        console.log('initestLab')
        const response = await uploadLabResult(testLabId, res.data)
        await updateTestLab(dataProgramPatient.value.programs[0].id, testLabId)
        // Redirect atau logika tambahan setelah upload sukses
        store.dispatch('updateAttribute', { attribute: 'unggahHasilTes', value: 'unggahHasilTes' })
        router.push(`/mainProgram`)
      } catch (error) {
        console.error('Upload failed:', error)
      }
    }

    return {
      previewVisible,
      previewImage,
      handleCancel,
      handlePreview,
      fileList,
      handleChange,
      handleSubmit,
      profileData,
    }
  },
}
</script>

<style scoped>
:global(.ant-upload-list-item-info::before) {
  left: 0 !important;
}
.doctor-prescription {
  /* max-width: 400px; */
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  text-align: center;
}

.doctor-info div {
  margin-bottom: 10px;
  text-align: left;
}

.upload-section {
  /* margin: 20px 0; */
}

.title {
  color: #333;
}

.label-col {
  text-align: right;
}

.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.col-12 {
  flex: 1;
}

.col-sm-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-sm-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
</style>
